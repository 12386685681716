<template>
  <div class="contact" style="padding-top: 100px; padding-bottom: 0px; background-color: var(--light)">
    <h2 style="line-height: 60px; font-weight: bold"><span class="tag_box">CONTACT US</span> <span class="accent">FOR&nbsp;A&nbsp;DEMO</span></h2>
    <div style="display: block; text-align: center; padding: 30px;">
      <div class="columns">
        <input class="input_text" v-model="name" type="text" placeholder="Your Full Name" style="display: block; height: 55px; margin-bottom: 20px; width: 400px">
        <input class="input_text" v-model="email" type="text" placeholder="Your Email Address" style="display: block; height: 55px; width: 400px">
      </div>
      <div class="columns">
        <textarea class="input_text" v-model="message" type="text" placeholder="Your Message" style="height: 130px; width: 400px"></textarea>
      </div>
      <div class="columns">
        <b-button :disabled="!((this.name != '') && (this.email.match('^[^ \r\n\t\f ]+@[^ \r\n\t\f ]+$')) && (!this.submitting))" v-on:click="send" class="input_text" style="height: 130px; width: 130px; color: black">Send</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "contact",
  data() {
    return {
      email: "",
      name: "",
      message: "",
      submitting: false
    };
  },
  methods: {
    send: function () {
      this.submitting = true;
      axios
        .post("https://api.miro.io/web/send-report", {
          name: this.name,
          email: this.email,
          message: "[runnertag.com] " + this.message
        })
        .then(() => {
          alert("Thanks for the message! We will get back to you soon. ");
          this.submitting = false;
          this.name = "";
          this.email = "";
          this.message = "";
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.input_text {
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  border-color: var(--secondary);
  border-style: solid;
  border-width: 4px;
  max-width: 80vw;
}

.columns {
  display: inline-block; 
  vertical-align: top; 
  margin-right: 20px; 
  margin-bottom: 40px; 
}

</style>
