<template>
  <div class="reviews">
    <div style="padding-top: 100px; padding-bottom: 100px; background: linear-gradient(140deg, var(--primary) 0%, var(--primary) 50%, var(--secondary) calc(50% + 1px), var(--secondary) 100%)">
      <div>
        <h2 style="font-weight: bold; line-height: 60px;"><span class="tag_box">PHOTO DELIVERY</span> <span style="color: var(--light);">SPEED&nbsp;IS&nbsp;CRITICAL</span></h2>
        <h3 style="color: var(--light); margin-top: 20px; padding: 0px 25px 0px 25px">We get lots of love &amp; un-rivaled engagement.</h3>
        <h3 style="margin-top: 15px; color: var(--light); padding: 0px 25px 0px 25px; font-weight: bold">RUNNERS&nbsp;&#9829;&nbsp;SPEED</h3>
        <h3 style="margin-top: 15px; color: var(--light); padding: 0px 25px 0px 25px; font-weight: bold">ORGANIZERS&nbsp;&#9829;&nbsp;EASY SETUP&nbsp;</h3>
        <h3 style="color: var(--light); margin-top: 15px; padding: 0px 25px 0px 25px">You don't have to take our word for it</h3>
        <br>
        <div style="display: block">
          <img src="@/assets/img/runner-say.jpg" class="img">
          <div style="display: inline-block; vertical-align: middle">
            <div class="sb">"I was impressed with the speed and number of pictures that were available so soon after the race."</div>
            <br>
            <div class="sb">"I loved when I saw a cameraman and smiled, it was sent to me!!"</div>
          </div>
        </div>
        <br>
        <div style="display: block">
          <img src="@/assets/img/cowley.jpg" class="img">
          <div style="display: inline-block; vertical-align: middle">
            <div class="sb">"Our sponsors were extremely pleased with the results &amp; it ranked as the #1 feature for runners."</div>
            <br>
            <div class="sb">"The speed at which the photos are processed &amp; sent to runners is impressive with many runner comments."</div>
          </div>
        </div>
        <br>
        <div style="display: block">
          <div style="display: inline-block; margin-bottom: 40px">
            <img src="@/assets/img/hc-cs.jpg" class="img">
            <div class="tb">
            11,094 Runners<br>
            28,461 Photos<br>
            679k Image Views<br>
            06:25 time-spent / runner<br>
            $21k USD Media Value
            </div>
          </div>
          <div style="display: inline-block; margin-bottom: 40px">
            <img src="@/assets/img/sl-cs.jpg" class="img">
            <div class="tb">
            1,904 Runners<br>
            10,818 Photos<br>
            203k Image Views<br>
            05:32 time-spent / runner<br>
            10k USD Media Value
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top: 100px; padding-bottom: 100px; background-color: var(--light)">
      <br>
      <a class="button" download href="https://runnertag.com/files/runnertag-ASIA-cs-slrr2017.pdf" target="_blank">Download Case Study</a><img src="@/assets/img/pdf-download.png" style="height: 40px; width: 40px"/>
      <br><br>
      <img src="@/assets/img/pdf.png" class="img_box">
      <br><br>
    </div>
    <div style="padding-top: 100px; padding-bottom: 100px; background: linear-gradient(140deg, white 0%, white 50%, var(--secondary) calc(50% + 1px), var(--secondary) 100%);">
      <h2 style="font-weight: bold; margin-top: 30px;"><span class="tag_box">OUR CLIENTS</span></h2>
      <br><br>
      <img src="@/assets/img/races/partners.png" class="img_box"/>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "reviews"
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.img {
  border-radius: 25px;
  width: 250px;
  max-width: 100%;
  height: 250px;
  border-width: 6px;
  border-color: var(--light);
  border-style: solid;
  top: 0px;
  margin: 15px;
  // margin-bottom: 5px;
}

.tb {
  width: 250px;
  max-width: 100%;
  height: auto; 
  border: 4px solid var(--light);
  background-color: var(--light);
  padding: 10px;
  text-align: center;
  font-weight: 900;
  color: var(--secondary);
  border-radius: 20px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto
}

.sb {
  width: auto;
  max-width: 60vw;
  margin: 0px;
  margin-left: 50px;
  border: 4px solid var(--secondary);
  background-color: var(--light);
  padding: 10px;
  text-align: center;
  font-weight: 900;
  color: var(--secondary);
  position: relative;
  display: block;
  border-radius: 20px;
}

.sb:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid var(--secondary);
  border-top: 10px solid var(--secondary);
  border-bottom: 10px solid transparent;
  left: -21px;
  top: 12px;
}

.sb:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid var(--light);
  border-top: 7px solid var(--light);
  border-bottom: 7px solid transparent;
  left: -11px;
  top: 16px;
}

.img_box {
  width: 1000px; 
  max-width: 80%; 
  background-color: var(--light); 
  padding: 1%; 
  border-radius: 16px; 
  border-width: 6px; 
  border-style: solid; 
  border-color: var(--secondary)
}

</style>
