<template>
  <div class="home">
    <navbar/>
    <b-card-body
        id="nav-scroller"
        ref="content"
        style="position: relative; height: 100vh; overflow-y: scroll; padding: 0px; overflow-x: hidden "
      >
      <banner id ="banner"/>
      <intro id="intro"/>
      <howitworks id="howitworks"/>
      <tools id="tools"/>
      <reviews id="reviews"/>
      <clouddata id="clouddata"/>
      <contact id="contact"/>
      <page_footer/>
    </b-card-body>
  </div>
</template>

<script>
// @ is an alias to /src
import banner from "@/components/banner.vue"
import navbar from "@/components/navbar.vue"
import intro from "@/components/intro.vue"
import howitworks from "@/components/howitworks.vue"
import tools from "@/components/tools.vue"
import reviews from "@/components/reviews.vue"
import clouddata from "@/components/clouddata.vue"
import contact from "@/components/contact.vue"
import page_footer from "@/components/page_footer.vue"

export default {
  name: "Home",
  components: {
    banner,
    navbar,
    intro,
    howitworks,
    tools,
    reviews,
    clouddata,
    contact,
    page_footer
  }
}
</script>

<style scoped lang="scss">

</style>