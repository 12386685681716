<template>
  <div class="footer">
    <div style="background-color: var(--light); padding: 20px;" class="d-flex justify-content-around flex-grow-0">
      <span style="color: var(--secondary)">&copy;2023 All rights reserved.
Trademarks & photos are the property of their respective owners. </span>
      <div class="d-flex justify-content-between flex-grow-0 gap-4">
        <span><a href="/dpp.html">Data Protection Policy</a></span>
        <span><a href="/tnc.html">Terms &amp; Conditions</a></span>
        <span><a href="/pp.html">Privacy Policy</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer"
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
