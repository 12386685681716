<template>
  <div class="tools">
    <div style="padding-top: 100px; padding-bottom: 100px; background-color: var(--light); ">
      <h2 style="font-weight: bold; line-height: 60px; padding-left: 20px; padding-right: 20px;"><span class="tag_box">TOOLS &amp; SERVICES</span> INCLUDED&nbsp;IN EVERY&nbsp;EVENT: </h2>
      <br>
      <div class="tool">
        <div class="tool_img">
          <img src="@/assets/img/tools-1-blk.png"/>
        </div>
        <h3>Easy Upload</h3>
      </div>
      <div class="tool">
        <div class="tool_img">
          <img src="@/assets/img/tools-4-blk.png"/>
        </div>
        <h3>Race-Day Support</h3>
      </div>
      <div class="tool">
        <div class="tool_img">
          <img src="@/assets/img/tools-2-blk.png"/>
        </div>
        <h3>Branded Gallery</h3>
      </div>
      <div class="tool">
        <div class="tool_img">
          <img src="@/assets/img/tools-5-blk.png"/>
        </div>
        <h3>Real-Time data</h3>
      </div>
      <div class="tool">
        <div class="tool_img">
          <img src="@/assets/img/tools-3-blk.png"/>
        </div>
        <h3>Branded Overlay</h3>
      </div>
      <br><br>
      <div style="margin-left: 10%; margin-right: 10%">
        <div style="border-radius: 16px; width: auto; background-color: white; position: relative; max-width: 1375px; text-align: center; margin-left: auto; margin-right: auto; margin-bottom: 10px; display: inline-block; padding: 50px;">
          <span style="font-size: 80px; position: absolute; left: 12px; top: -25px; margin: 0px;">+</span>
          <span style="">Lifetime Photo/Gallery Hosting &bull; Sponsor Media Report &bull; Gallery Traffic Report &bull; Facebook/Email Share &bull; Reporting Api Access &bull; Post-Event Surveys &bull; Custom Overlay Design &bull; Custom Gallery Design &bull; Email Setup (Mailchimp) &bull; Email System Integration &bull; Custom Apis</span>
        </div>
      </div>
      <br><br><br>
      <a class="button" href="#contact">GET A DEMO TODAY</a>
      <br><br>
    </div>
  </div>
</template>

<script>
export default {
  name: "tools"
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.tool {
  display: inline-block;
  margin: 10px;
  margin-bottom: 15px;
}

.tool_img {
  background-color: white;
  border-radius: 16px;
  padding: 5px;
  margin-bottom: 10px;
}

</style>
