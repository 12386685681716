<template>
  <div class="data">
    <div style="padding-top: 100px; padding-bottom: 100px; background-color: var(--primary)">
      <h2 style="color: var(--secondary); font-weight: bold; line-height: 60px; "><span class="tag_box">RUNNER<span class="accent">TAG</span> CLOUD</span><span style="color: var(--light); "> IN&nbsp;REAL&#8209;TIME</span></h2>
      <h4 style="color: var(--light); margin-top: 20px; padding: 0px 25px 0px 25px">Full, detailed engagement results are available to RT customers during &amp; after their events!</h4>
      <br>
      <observer @on-change="onChange">
        <div class="circle"><span class="stats">Runners<br>Found:<br><number class="num" :format="formatNumber" animationPaused ref="runners_count" :from="0" :to="runners_count" :duration="2" easing="Power2.easeOut"/></span></div>
        <div class="circle"><span class="stats">Images<br>Tagged:<br><number class="num" :format="formatNumber" animationPaused ref="images_count" :from="0" :to="images_count" :duration="2" easing="Power2.easeOut"/></span></div>
        <br>
        <div class="circle"><span class="stats">Images<br>Viewed:<br><number class="num" :format="formatNumber" animationPaused ref="interactions_count" :from="0" :to="interactions_count" :duration="2" easing="Power2.easeOut"/></span></div>
        <div class="circle bigger1"><span class="stats">Media<br>Value:<br><strong>$</strong><number class="num" :format="formatNumber" animationPaused ref="interactions_mv" :from="0" :to="interactions_mv" :duration="2" easing="Power2.easeOut"/></span></div>
        <div class="circle"><span class="stats">Brand<br>Interactions:<br><number class="num" :format="formatNumber" animationPaused ref="interactions_reach" :from="0" :to="interactions_reach" :duration="2" easing="Power2.easeOut"/></span></div>
        <br>
        <div class="circle"><span class="stats">Estimated<br>Reach:<br><number class="num" :format="formatNumber" animationPaused ref="views_count" :from="0" :to="views_count" :duration="2" easing="Power2.easeOut"/></span></div>
        <div class="circle"><span class="stats">Bibs<br>Searched:<br><number class="num" :format="formatNumber" animationPaused ref="searches_count" :from="0" :to="searches_count" :duration="2" easing="Power2.easeOut"/></span></div>
        <br>
        <div class="circle bigger2"><span class="stats">Media<br>Value:<br><strong>$</strong><number class="num" :format="formatNumber" animationPaused ref="interactions_mv2" :from="0" :to="interactions_mv" :duration="2" easing="Power2.easeOut"/></span></div>
      </observer>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import numeral from 'numeral';
import 'numeral/locales';

import Observer from 'vue-intersection-observer'
import VueNumber from 'vue-number-animation'
import Vue from 'vue'
Vue.use(VueNumber)

export default {
  name: "data",
  data() {
    return {
      images_count: 0,
      runners_count: 0,
      interactions_count: 0,
      interactions_reach: 0,
      interactions_mv: 0,
      views_count: 0,
      searches_count: 0
    }
  },
  components: {
    Observer
  },
  mounted () {
    axios
      .get("//runnertag.com/data/usage.json")
      .then(response => (
        console.log(response),
        this.images_count = response.data.images[0].count,
        this.runners_count = response.data.runners[0].count,
        this.interactions_count = response.data.interactions[0].count,
        this.interactions_reach = response.data.interactions[0].reach,
        this.interactions_mv = response.data.interactions[0].mv * 1000000,
        this.views_count = response.data.views[0].count,
        this.searches_count = response.data.searches[0].count
      )
    )
  },
  methods: {
    onChange(entry, unobserve) {
      // After loading Cancel monitoring, optimise performance
      if (entry.isIntersecting) {
        unobserve();
        this.$refs.images_count.play();
        this.$refs.runners_count.play();
        this.$refs.interactions_count.play();
        this.$refs.interactions_reach.play();
        this.$refs.interactions_mv.play();
        this.$refs.interactions_mv2.play();
        this.$refs.views_count.play();
        this.$refs.searches_count.play();
      }
    },
    formatNumber(number) {
      return numeral((number/1000).toFixed(0)*1000).format('0,0')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.circle {
  font-size: 0.8em;
  font-weight: regular;
  display: inline-block;
  width: 150px;
  height: 150px;
  line-height: 130px;
  border-radius: 77px;
  background-color: var(--light);
  margin: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: auto;
  top: auto;
  bottom: auto;
  vertical-align: middle;
  border-color: var(--secondary);
  border-width: 4px;
  border-style: solid;
  text-transform: uppercase;
  strong {
    font-size: 1.5em;
  }
}

.stats {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  line-height: normal;
  margin-bottom: 10px;
  font-size: medium;
}

.num {
  font-weight: bold;
  font-size: 1.4em;
}

.bigger1 {
  width: 170px; 
  height: 170px; 
  line-height: 140px; 
  border-radius: 85px; 
  font-size: 22px;
  .stats {
    font-size: large;
  }
}

.bigger2 {
  display: none;
  width: auto; 
  border-radius: 70px; 
  font-size: large; 
  width: 300px;
}

@media (max-width: 800px) {
  .circle {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bigger1 {
    display: none;
  }
  .bigger2 {
    display: inline-block;
    height: 140px;
  }
}

</style>
