<template class="banner">
  <div id="banner" style="">
    <div id="banner-inner">
      <h3>We’re excited to announce that RunnerTag has been acquired by Greenfly!</h3>We will continue to provide the same great service and are excited to work with more partners worldwide. Read more about this exciting milestone here.
      <a class="btn btn-outline btn-secondary" href="https://www.greenfly.com/press-releases/greenfly-acquires-artificial-intelligence-provider-miro-ai">Learn more <span aria-hidden="true">→</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'banner'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#banner {
  background-image: url('~@/assets/img/Greenfly-Miro-AI-1280x720.png');
  background-position: center;
	background-size: cover;
}
#banner-inner {
  background-color: rgba(67,183,57,0.4);
  color: white;
  margin-top: 4em;
  padding-top: 18em;
  padding-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  height: 30em;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  h3 { 
    font-weight: bold;
    font-size: 1.5;
  }
}

</style>
