<template class="navbar">
  <div>
    <b-navbar toggleable="lg" type="light" variant="light" style="position: fixed; top: 0px; left: 0px; z-index: 100; width: 100%; padding-right: 10px;">
      <b-navbar-brand href="#intro"><img src="@/assets/img/runnertag_logo-front-card.png" style="width: 200px; height: auto; margin-bottom: 5px; margin-left: 10px;"></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" style="right: 15px;"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav style="transition-duration: 0s;">
        <b-nav pills align="right" style="right: 15px; margin-left: auto; transition-duration: 0s;" v-b-scrollspy:nav-scroller>
          <b-nav-item class="items" href="#howitworks">How it works</b-nav-item>
          <b-nav-item class="items" href="#tools">Tools</b-nav-item>
          <b-nav-item class="items" href="#reviews">Results</b-nav-item>
          <b-nav-item class="items" href="#clouddata">Data</b-nav-item>
          <!-- <b-nav-item class="items" href="#pricing">Pricing</b-nav-item> -->
          <b-nav-item class="items" href="#contact">Contact Us</b-nav-item>
          <b-nav-item class="items" href="https://dash.runnertag.com/" style="border-color: var(--primary)">Sign In</b-nav-item>
        </b-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'navbar'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.show > ul {
  display: block;
}

.items {
  padding: 0px;
  margin-left: 5px;
  border-style: solid;
  border-width: 3px;
  border-radius: 8px;
  border-color: transparent;
  transition-duration: 0.25s;

  .active {
    background-color: var(--primary);
    border-radius: 5px;
  }
}

.items:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  border-radius: 8px;
}

</style>
