<template>
  <div class="intro">
    <div style="background-image: url('@/assets/img/header-bg.jpg'); padding-top: 150px; background-repeat:no-repeat; background-size: cover; ">
        <h1 style="font-size: 50px; line-height: 70px;"><span>FIND &amp;</span> <span style="font-weight: bold; border-style: solid; border-color: var(--secondary); border-width: 6px; padding: 6px; background-color: white">TAG</span> <span>RUNNERS</span> <span class="accent">INSTANTLY!</span></h1>
        <br>
        <h4 style="padding: 0px 30px 0px 30px"><span class="accent">No</span> special equipment, personnel or printing required!</h4>
        <br><br>
        <a href="#howitworks" class="button">LEARN MORE</a>
        <a href="https://dash.runnertag.com/" class="button">TRY IT OUT!</a>
        <img src="@/assets/img/runners2.png" class="runners" style="display: block; margin: auto; margin-top: 20px; "/>
      </div>
  </div>
</template>

<script>
export default {
  name: "intro"
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
