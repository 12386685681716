<template>
  <div class="howitworks">
    <div style="padding-top: 100px; padding-bottom: 100px; background: linear-gradient(140deg, white 0%, white 50%, var(--primary) calc(50% + 1px), var(--primary) 100%);">
      <div style="display: block;">
        <h2 style="font-weight: bold">How Runner<span class="accent">Tag</span> Works</h2>
        <h4 style="margin-top: 15px; line-height: 40px; padding: 0px 25px 0px 25px">Each photo takes less than <span class="accent">4&nbsp;seconds</span> to <span style="font-weight: bold; border-style: solid; border-color: var(--secondary); border-width: 3px; padding: 3px; background-color: white;">TAG</span> &amp; add branding or sponsors!</h4>
      </div>
      <div>
        <div class="steps">
          <img src="@/assets/img/runner-polor-start.png" style="width: 70%; height: auto"/>
          <h5 class="pill"><div class="circle">1.</div> <span>Take Photos &amp; Upload</span></h5>
          <p class="info">Take your same great photos and upload it to RUNNER<span class="accent">TAG dashboard!</span></p>
        </div>
        <div class="steps">
          <img src="@/assets/img/runnertag-cloud.png" style="width: 100%; height: auto"/>
          <h5 class="pill"><div class="circle">2.</div> We Find the Runners</h5>
          <p class="info"><span class="accent">VERY FAST:</span> Currently  <span class="accent">@3.62s</span> per photo and <span class="accent">97.13%</span> accuracy</p>
        </div>
        <div class="steps">
          <img src="@/assets/img/runner-polor-end.png" style="width: 70%; height: auto"/>
          <h5 class="pill"><div class="circle">3.</div> Runners Get Photos</h5>
          <p class="info" style="cursor: pointer;">Contact us to learn about the RUNNERTAG<span class="accent">TAG</span> API</p>
        </div>
      </div>
      <br>
      <div style="display: block; margin-top: 0px">
        <h2 style="font-weight: bold; color: var(--light)">Want to try it?</h2>
        <!-- <p style="color: var(--light)">Upload your image and ...</p> -->
        <br>
        <a class="button" style="background-color: var(--secondary); border-color: var(--secondary)" href="#contact">CONTACT US</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "howitworks"
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.pill {
  background-color: var(--secondary);
  border-radius: 34px;
  padding: 0px;
  padding-right: 20px;
  color: white;
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  height: auto;
}

.circle {
  background-color: var(--primary);
  border-radius: 24px;
  padding: 8px;
  color: white;
  font-weight: bold;
  margin: 8px;
  width: 42px;
  height: 42px;
  display: inline-block;
}

.info {
  border-radius: 20px;
  padding: 10px;
  background-color: var(--light);
  color: var(--secondary);
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  height: auto;
  max-width: 100%;
  font-size: 12px;
}

.steps {
  display: inline-block; 
  margin: 10px; 
  max-width: 100vw; 
  width: 400px; 
  vertical-align: middle;
}

</style>
